<template>
  <v-card tile elevation="1" class="fill-height" style="border-left: 3px solid #5bb55f" min-height="280" >
    <v-card-title class="pa-0">
      <v-toolbar elevation="0" class="pa-0">
        <v-toolbar-title class="toolbar-work-place-title">
          Нормативні параметри
        </v-toolbar-title>
        <v-spacer></v-spacer>

        <v-menu offset-y :close-on-content-click="true" tile class="elevation-0"
                :content-class="'menu-without-border'"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                class="grey lighten-4"
                icon
                outlined
                v-bind="attrs"
                v-on="on"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <div class="px-2 pt-2">
            <MainModal
              :main="{ component: 'TariffOrganization', title: 'Тарифи по організації' }"
              :button="{ icon: false, color: 'grey lighten-4', buttonIcon: 'mdi-plus', buttonIconClass: 'mr-2',
              buttonBlock: true, buttonClass: 'mb-3', buttonIconLeft: true, buttonStyle: 'justify-content: flex-start',
              buttonName: 'Тариф по організації (зона дії уся організація)' }"
              :item="{ from_flat: true, date_start: current_month }"
              @updateItemModal="flatIndicatorUpdateItemModal"
          />
          </div>
          <div class="px-2 pb-5">
            <MainModal
                :main="{ component: 'TariffBuilding', title: 'Тарифи по будинку' }"
                :button="{ icon: false, color: 'grey lighten-4', buttonIcon: 'mdi-plus', buttonIconClass: 'mr-2',
                buttonBlock: true, buttonIconLeft: true, buttonStyle: 'justify-content: flex-start',
                buttonName: 'Тариф по будинку (зона дії увесь будинок)' }"
                :item="{ from_flat: true, date_start: current_month, building_id: this.flat.building_id }"
                @updateItemModal="flatIndicatorUpdateItemModal"
            />
          </div>

        </v-menu>
      </v-toolbar>
    </v-card-title>
    <v-data-table
        :headers="houseParameterHeaders"
        :items="flat_all_tariff"
        hide-default-footer
        class="custom-table custom-table-1"
        @click:row="openFlatIndicatorModal"
    >
      <template v-slot:item.icon="{ item }">
        <MainModal
            v-if="item.type === 'organization'"
            :main="{ component: 'TariffOrganization', title: 'Тарифи по організації' }"
            :button="{ iconActivator: true, class: 'mr-2', color: getIcon(item.icon, 'color'), buttonIcon: getIcon(item.icon, 'icon'), iconSize: 26 }"
            :item="{
                      id: item.id,
                      service_id: item.service_id,
                      tariff_group_type_id: item.tariff_group_type_id,
                      date_start: item.date_start,
                      date_end: item.date_end,
                      value_dec: item.value_dec,
                      value_dec_second: item.value_dec_second,
                      value_dec_third: item.value_dec_third,
                      is_legacy: item.is_legacy,
                      from_flat: true
                    }"
            :modal="flat_service_dialog_id === item.id"
            @updateItemModal="flatIndicatorUpdateItemModal"
        />
        <MainModal
            v-if="item.type === 'building'"
            :main="{ component: 'TariffBuilding', title: 'Тарифи по будинку' }"
            :button="{ iconActivator: true, class: 'mr-2', color: getIcon(item.icon, 'color'), buttonIcon: getIcon(item.icon, 'icon'), iconSize: 26 }"
            :item="{
                      id: item.id,
                      building_id: item.building_id,
                      service_id: item.service_id,
                      tariff_group_type_id: item.tariff_group_type_id,
                      date_start: item.date_start,
                      date_end: item.date_end,
                      value_dec: item.value_dec,
                      value_dec_second: item.value_dec_second,
                      value_dec_third: item.value_dec_third,
                      is_legacy: item.is_legacy,
                      from_flat: true
                    }"
            :modal="flat_service_dialog_id === item.id"
            @updateItemModal="flatIndicatorUpdateItemModal"
        />
      </template>

      <template v-slot:item.service_name="{ item }">
        <span :class="{'font-weight-medium': item.actual}">
          {{ item.service_name }}
        </span>
      </template>

      <template v-slot:item.tariff_type="{ item }">
        <span :class="{'font-weight-medium': item.actual}">
          {{ item.tariff_type }}
        </span>
      </template>

      <template v-slot:item.tariff_group_type_name="{ item }">
        <span :class="{'font-weight-medium': item.actual}">
          {{ item.tariff_group_type_name }}
        </span>
      </template>

      <template v-slot:item.date_start="{ item }">
        <span :class="{'font-weight-medium': item.actual}">
          {{ item.date_start | formatDate }}
        </span>
      </template>

      <template v-slot:item.date_end="{ item }">
        <span>
          {{ item.date_end | formatDate }}
        </span>
      </template>

      <template v-slot:item.value_dec="{ item }">
        <span :class="{'font-weight-medium': item.actual}">
          {{ item.value_dec | formatNumber('0.000000') }}
        </span>
      </template>

      <template v-slot:item.actual="{ item }">
        <v-simple-checkbox :value="item.actual" color="success" class="mt-0"/>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>

import MainModal from "@/components/modal/MainModal";
import {mapActions, mapGetters} from "vuex";
import {GET_ALL_LEGACY_FLAT_TARIFF} from "@/store/actions/flat_service";
import {accountIcons} from "@/utils/icons";

export default {
  name: "HWL_TariffForFlat",
  components: { MainModal },
  data() {
    return {
      flat_service_dialog_id: 0,
      houseParameterHeaders: [
        { text: '', value: 'icon', width: 26 },
        { text: 'Послуга', value: 'service_name' },
        { text: 'Тип тарифу', value: 'tariff_type'},
        { text: 'Група тарифу', value: 'tariff_group_type_name' },
        { text: 'Початок', value: 'date_start' },
        { text: 'Закінчення', value: 'date_end' },
        { text: 'Тариф', value: 'value_dec', width: 110},
        { text: 'Актуальний', value: 'actual', align: 'center' },
      ],
    }
  },
  computed: {
    ...mapGetters({
      flat_all_tariff: 'getAllFlatTariff',
      flat: 'getLegacyFlat',
      settings: 'getGeneralSettings',
      current_month: 'getCurrentMonthLegacy'
    }),
  },
  methods: {
    ...mapActions({
      fetchAllFlatTariff: GET_ALL_LEGACY_FLAT_TARIFF
    }),
    getIcon(name, type) {
      const defaultIcon = {icon: 'mdi-text-box-check-outline', color: 'success'}
      const value = accountIcons[name] || defaultIcon
      return value[type]
    },
    flatIndicatorUpdateItemModal(payload) {
      this.flat_service_dialog_id = 0
      if (payload) {
        if (payload.payload === 'fetch') {
          this.$emit('fetch')
        }
      }
    },
    openFlatIndicatorModal(row) {
      this.flat_service_dialog_id = row.id
    }
  },
  mounted() {
    if (this.$route.params.person_hash) {
      this.fetchAllFlatTariff(this.$route.params.person_hash)
    }
  },
  watch: {
    person_hash: {
      immediate: true,
      handler(payload) {
        if (payload) {
          this.fetchAllFlatTariff(payload)
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .menu-without-border {
    box-shadow: none;
  }
</style>