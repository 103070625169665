var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"fill-height",staticStyle:{"border-left":"3px solid #5bb55f"},attrs:{"tile":"","elevation":"1","min-height":"280"}},[_c('v-card-title',{staticClass:"pa-0"},[_c('v-toolbar',{staticClass:"pa-0",attrs:{"elevation":"0"}},[_c('v-toolbar-title',{staticClass:"toolbar-work-place-title"},[_vm._v(" Нормативні параметри ")]),_c('v-spacer'),_c('v-menu',{staticClass:"elevation-0",attrs:{"offset-y":"","close-on-content-click":true,"tile":"","content-class":'menu-without-border'},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"grey lighten-4",attrs:{"icon":"","outlined":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('div',{staticClass:"px-2 pt-2"},[_c('MainModal',{attrs:{"main":{ component: 'TariffOrganization', title: 'Тарифи по організації' },"button":{ icon: false, color: 'grey lighten-4', buttonIcon: 'mdi-plus', buttonIconClass: 'mr-2',
            buttonBlock: true, buttonClass: 'mb-3', buttonIconLeft: true, buttonStyle: 'justify-content: flex-start',
            buttonName: 'Тариф по організації (зона дії уся організація)' },"item":{ from_flat: true, date_start: _vm.current_month }},on:{"updateItemModal":_vm.flatIndicatorUpdateItemModal}})],1),_c('div',{staticClass:"px-2 pb-5"},[_c('MainModal',{attrs:{"main":{ component: 'TariffBuilding', title: 'Тарифи по будинку' },"button":{ icon: false, color: 'grey lighten-4', buttonIcon: 'mdi-plus', buttonIconClass: 'mr-2',
              buttonBlock: true, buttonIconLeft: true, buttonStyle: 'justify-content: flex-start',
              buttonName: 'Тариф по будинку (зона дії увесь будинок)' },"item":{ from_flat: true, date_start: _vm.current_month, building_id: this.flat.building_id }},on:{"updateItemModal":_vm.flatIndicatorUpdateItemModal}})],1)])],1)],1),_c('v-data-table',{staticClass:"custom-table custom-table-1",attrs:{"headers":_vm.houseParameterHeaders,"items":_vm.flat_all_tariff,"hide-default-footer":""},on:{"click:row":_vm.openFlatIndicatorModal},scopedSlots:_vm._u([{key:"item.icon",fn:function({ item }){return [(item.type === 'organization')?_c('MainModal',{attrs:{"main":{ component: 'TariffOrganization', title: 'Тарифи по організації' },"button":{ iconActivator: true, class: 'mr-2', color: _vm.getIcon(item.icon, 'color'), buttonIcon: _vm.getIcon(item.icon, 'icon'), iconSize: 26 },"item":{
                    id: item.id,
                    service_id: item.service_id,
                    tariff_group_type_id: item.tariff_group_type_id,
                    date_start: item.date_start,
                    date_end: item.date_end,
                    value_dec: item.value_dec,
                    value_dec_second: item.value_dec_second,
                    value_dec_third: item.value_dec_third,
                    is_legacy: item.is_legacy,
                    from_flat: true
                  },"modal":_vm.flat_service_dialog_id === item.id},on:{"updateItemModal":_vm.flatIndicatorUpdateItemModal}}):_vm._e(),(item.type === 'building')?_c('MainModal',{attrs:{"main":{ component: 'TariffBuilding', title: 'Тарифи по будинку' },"button":{ iconActivator: true, class: 'mr-2', color: _vm.getIcon(item.icon, 'color'), buttonIcon: _vm.getIcon(item.icon, 'icon'), iconSize: 26 },"item":{
                    id: item.id,
                    building_id: item.building_id,
                    service_id: item.service_id,
                    tariff_group_type_id: item.tariff_group_type_id,
                    date_start: item.date_start,
                    date_end: item.date_end,
                    value_dec: item.value_dec,
                    value_dec_second: item.value_dec_second,
                    value_dec_third: item.value_dec_third,
                    is_legacy: item.is_legacy,
                    from_flat: true
                  },"modal":_vm.flat_service_dialog_id === item.id},on:{"updateItemModal":_vm.flatIndicatorUpdateItemModal}}):_vm._e()]}},{key:"item.service_name",fn:function({ item }){return [_c('span',{class:{'font-weight-medium': item.actual}},[_vm._v(" "+_vm._s(item.service_name)+" ")])]}},{key:"item.tariff_type",fn:function({ item }){return [_c('span',{class:{'font-weight-medium': item.actual}},[_vm._v(" "+_vm._s(item.tariff_type)+" ")])]}},{key:"item.tariff_group_type_name",fn:function({ item }){return [_c('span',{class:{'font-weight-medium': item.actual}},[_vm._v(" "+_vm._s(item.tariff_group_type_name)+" ")])]}},{key:"item.date_start",fn:function({ item }){return [_c('span',{class:{'font-weight-medium': item.actual}},[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.date_start))+" ")])]}},{key:"item.date_end",fn:function({ item }){return [_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.date_end))+" ")])]}},{key:"item.value_dec",fn:function({ item }){return [_c('span',{class:{'font-weight-medium': item.actual}},[_vm._v(" "+_vm._s(_vm._f("formatNumber")(item.value_dec,'0.000000'))+" ")])]}},{key:"item.actual",fn:function({ item }){return [_c('v-simple-checkbox',{staticClass:"mt-0",attrs:{"value":item.actual,"color":"success"}})]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }